<template>
  <div>
    <v-progress-linear fixed color="primary" indeterminate v-if="loading"></v-progress-linear>
    <v-container>
      <v-row>
        <v-col cols="12" md="12" class="px-6">
          <v-row>
            <v-col>
              <h2 class="mt-5 mb-3">Checkliste auswählen</h2>
              <p class="">Fahrzeug auswählen und die Checklisten ausfüllen</p>
              <p class="">Immer mit der <b>ersten Checkliste</b> pro Fahrzeug starten.</p>
              <p>Die Checkliste kann während dem bearbeiten gespeichert und verlassen werden, sie ist dann aber nicht
                abgeschlossen. Zum Abschliessen der Checkliste immer den Absenden Button drücken (grüner Button).</p>
            </v-col>
          </v-row>
          <v-row v-if="messageText">
            <v-col>
              <v-alert

                  border="left"
                  color="indigo"
                  dark
                  class="my-0"
              >
                {{ messageText }}
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <h3 class="mb-4">Alle Fahrzeuge:</h3>

              <v-expansion-panels
                  v-model="panel"
                  v-if="vehicleData && vehicleData.vehicles"
              >
                <v-expansion-panel
                    v-for="vehicle in vehicleData.vehicles"
                    :key="vehicle._id"
                >
                  <v-expansion-panel-header>
                    <v-row no-gutters>
                      <v-col cols="9">
                        {{ vehicle.name }}
                      </v-col>
                      <v-col
                          cols="3"
                          class="text--secondary"
                      >
                        <v-row
                            no-gutters
                            style="width: 100%"
                        >
                          <v-col cols="6">

                          </v-col>
                          <v-col cols="6" v-if="vehicleData.vehicleComplete[vehicle._id]">
                            <v-icon color="success">
                              mdi-check-circle
                            </v-icon>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list
                        flat
                    >
                      <v-list-item-group
                          color="primary"
                      >
                        <v-list-item
                            v-for="list in vehicle.lists"
                            :key="list._id"
                            @click="selectChecklist(list)"
                        >
                          <v-list-item-icon>
                            <v-icon v-if="vehicleData.counts[list._id] && vehicleData.counts[list._id] > 0"
                                    color="success">mdi-clipboard-list
                            </v-icon>
                            <v-icon v-else>mdi-clipboard-list</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title v-text="list.title"></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

            </v-col>
          </v-row>

          <v-row v-if="fileList">
            <v-col class="pb-0" cols="12" md="12">
              <h3 class="mt-2">Downloads:</h3>
            </v-col>
            <v-col v-for="file in fileList" :key="file.title" class="" cols="12" lg="2" md="4" sm="6" xs="12">
              <a target="_blank" :href="generateFileDownloadUrl(file.path)" style="text-decoration: none;">
                <v-card>
                  <v-card-title class="subtitle-2">
                    {{ file.title }}
                  </v-card-title>
                  <v-card-text class="d-flex justify-space-around">
                    <v-icon style="font-size: 54px" class="">
                      mdi-file-download
                    </v-icon>
                  </v-card-text>
                </v-card>
              </a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-dialog
          v-model="dialog.confirm"
          persistent
          max-width="290"
      >
        <v-card>
          <v-card-title class="">
            Checkliste {{ selectedListName }} öffnen?
          </v-card-title>
          <v-card-text>Eine neue Checkliste wird eröffnet und kann ausgefüllt werden.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color=""
                text
                @click="dialog.confirm = false"
            >
              Abbrechen
            </v-btn>
            <v-btn
                color="primary"
                text
                @click="useSelectedChecklist()"
            >
              Weiter
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar
          v-model="snack.state"
          :timeout="snack.timeout"
          :color="snack.color"
          elevation="12"
          top
          right
      >
        {{ snack.message }}
      </v-snackbar>

    </v-container>
    <v-snackbar
        v-model="snack.state"
        :timeout="snack.timeout"
        :color="snack.color"
        elevation="12"
        top
        right
    >
      {{ snack.message }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Home",
  data() {
    return {
      vehicle: null,
      vehicleData: null,
      loading: true,
      userName: '',
      fileList: null,
      panel: null,
      selectedChecklist: null,
      selectedListName: '',
      checklists: null,
      messageText: null,
      dialog: {
        confirm: false
      },
      snack: {
        state: false,
        color: 'success',
        timeout: 2000,
        message: 'Checkliste wurde erfolgreich gespeichert!',
      }

    }
  },
  created() {
    this.getVehicles()
    this.getMessageText()
    this.getFiles()
  },
  mounted() {

  },
  methods: {
    getVehicles() {
      this.loading = true
      axios
          .get(process.env.VUE_APP_API_URL + '/vehicles')
          .then((response) => {
            this.vehicleData = response.data
          })
          .catch((err) => {
            console.log(err)
            this.showAlert('Serverfehler. Daten konnten nicht geladen werden.', 'error', 5000)
          })
          .finally(() => {
            this.loading = false
          })
    },
    getFiles() {
      this.loading = true
      axios
          .get(process.env.VUE_APP_API_URL + '/files')
          .then((response) => {
            this.fileList = response.data
          })
          .catch((err) => {
            console.log(err)
            this.showAlert('Serverfehler. Dateien konnten nicht geladen werden.', 'error', 5000)
          })
          .finally(() => {
            this.loading = false
          })
    },
    getMessageText() {
      axios.get('/messages')
          .then((response) => {
            if (response.data) {
              this.messageText = response.data.text
            } else {
              this.messageText = null
            }
          })
    },
    showAlert(message, color = 'success', timeout = 2000) {
      this.snack.message = message
      this.snack.color = color
      this.snack.timeout = timeout
      this.snack.state = true
    },
    selectChecklist(list) {
      this.selectedChecklist = list
      this.selectedListName = list.title
      this.dialog.confirm = true
    },
    useSelectedChecklist() {
      this.$router.push({name: 'PopulateChecklist', params: {id: this.selectedChecklist._id, newList: 'true'}})
    },
    generateFileDownloadUrl(path) {
      return process.env.VUE_APP_STATIC_URL + '/attachments/' + path
    }
  }
}
</script>

<style lang="scss">
.v-dialog > .v-card > .v-card__title {
  font-size: 1rem;
}

.container {

}

a {
  .v-icon:hover {
    color: #1976d2;
  }
}

</style>