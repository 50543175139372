import axios from 'axios';

const state = {
    user: null,
    navList: []
};

const getters = {
    isAuthenticated: (state) => { return !!state.user }
};

const actions = {
    async LogIn({commit}, User) {
        await axios.post('/auth/login', User)
        await commit('setUser', User.username)

        await axios.get('/auth/navlist')
            .then((res)=>{
                commit('setNavList', res.data)
            })
    },
    async LogOut({commit}){
        await axios.get('/auth/logout')
        let user = null
        commit('logout', user)
    }
};

const mutations = {
    setNavList(state, navlist){
        state.navList = navlist
    },
    setUser(state, username){
        state.user = username
    },
    logout(state){
        state.user = null
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};