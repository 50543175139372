import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        meta: { requiresAuth: true },
        component: Home
    },
    {
        path: '/admin',
        name: 'Admin',
        meta: { requiresAuth: true },
        component: () => import('../views/Admin.vue')
    },
    {
        path: '/checklistcreator/:id',
        name: 'ChecklistCreator',
        props: true,
        meta: { requiresAuth: true },
        component: () => import('../views/ChecklistCreator.vue')
    },
    {
        path: '/populatechecklist/:id',
        name: 'PopulateChecklist',
        props: true,
        meta: { requiresAuth: true },
        component: () => import('../views/PopulateChecklist')
    },
    {
        path: '/checklists/review',
        name: 'Review',
        props: true,
        meta: { requiresAuth: true },
        component: () => import('../views/Review')
    },
    {
        path: '/checklists/open',
        name: 'Open',
        props: true,
        meta: { requiresAuth: true },
        component: () => import('../views/Open')
    },
    {
        path: '/checklists/archived',
        name: 'Archive',
        props: true,
        meta: { requiresAuth: true },
        component: () => import('../views/Archive')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue')
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isAuthenticated) {
            next()
            return
        }
        next('/login')
    } else {
        next()
    }
})

export default router
