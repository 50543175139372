<template>
  <v-app id="fw_checklist">
    <v-app-bar
        app
        color="white"
        text
    >
      <a href="/">
        <v-img
            class="mx-2 d-none d-sm-block"
            src="@/assets/images/logo-fwzssg.png"
            max-height="40"
            max-width="180"
            contain
        ></v-img>
        <v-img
            class="mx-0 d-block d-sm-none"
            src="@/assets/images/logo-fwzssg.png"
            max-height="30"
            max-width="180"
            height="30"
            contain
        ></v-img>
      </a>

      <v-spacer></v-spacer>
        <v-btn
            color=""
            icon
            small
            class="mr-2"
            @click="sheet = !sheet"
        >
          <v-icon>mdi-information</v-icon>
        </v-btn>
      <v-toolbar-items class="" v-if="$store.getters.isAuthenticated">
        <v-menu
            :offset-y="true"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                text
                v-bind="attrs"
                v-on="on"
            >
              <v-icon left dark>mdi-account</v-icon>
              {{ $store.state.auth.user }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item
            >
              <v-btn @click="logout" text plain>Logout</v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>

    <v-main class="background">
      <v-sheet min-height="100%">
        <router-view>

        </router-view>
      </v-sheet>
    </v-main>
    <v-bottom-navigation
        :value="value"
        color="red"
        dark
        grow
        fixed
        v-if="$store.getters.isAuthenticated">
      <v-btn class="nav-link" v-for="link in $store.state.auth.navList" :to="link.path" v-bind:key="link.name">
        <span>{{ link.name }}</span>
        <v-icon>{{ link.icon }}</v-icon>
      </v-btn>
    </v-bottom-navigation>
    <div class="" style="height: 56px">

    </div>

    <div class="text-center">
      <v-bottom-sheet
          v-model="sheet"
      >
        <v-sheet
            class="text-center"
            height="200px"
        >
          <v-btn
              class="mt-6"
              text
              color="error"
              @click="sheet = !sheet"
          >
            Schliessen
          </v-btn>
          <div class="my-3 v-size--small">
            <h4 class="mb-4">FWZSSG Fahrzeug Checklisten V1.0</h4>
            <p class="subtitle-2">Copyright © {{ new Date().getFullYear() }} goeldi technology.<br>
            Kontakt: <a href="mailto:support@goelditechnology.ch">support@goelditechnology.ch</a></p>
          </div>
        </v-sheet>
      </v-bottom-sheet>
    </div>
  </v-app>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    links: [],
    sheet: false
  }),
  created() {
    this.checkLoginState()
  },
  methods: {
    async logout() {
      await this.$store.dispatch('LogOut')
      this.$router.push('/login')
    },
    checkLoginState () {
      axios.get(process.env.VUE_APP_API_URL + '/vehicles')
          .then(() => {
            console.log("login ok")
          })
          .catch(function (error) {
            if (error.response && error.response.status === 401) {
              this.logout()
            }
          });
    }
  }
}
</script>


<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap');

#fw_checklist {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: 'Montserrat', sans-serif;
}

.background {
  background-image: url("./assets/images/bg.jpg");
  background-size: cover;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.nav-link {
  height: 100% !important;
}
</style>
